<template>
    <div class="my-context">
        <div style="display: flex;justify-content: space-between;">
            <div>
                <h2 class="title">兑换App</h2>
                <h5 class="title" style="color: #0000007f;font-size: 15px;font-weight: 300;">使用您的兑换码兑换一个或多个App。</h5>
            </div>
            <div class="create-app" @click="showExchangeAppModal = true"
                style="color:#2869ff;font-size: 16px;cursor: pointer;display: flex;align-items: center;font-weight: 500;">
                <i class="bi bi-cloud-plus-fill" style="font-size: 20px;"></i>&nbsp;兑换
            </div>
        </div>

        <a-modal class="modal-info" v-model:open="showExchangeAppModal" :closable="!reqExchangeAppStatus"
            :maskClosable="false" :footer="null">
            <template #title>
                <div ref="modalTitleRef"
                    style="width: 100%;font-size: 24px;font-weight: 600;color: #494949;margin:20px 0px 55px 0px;">兑换
                    App
                </div>
            </template>

            <div>
                <!-- <h4 style="margin-bottom: 5px;opacity: 0.8;">兑换码</h4> -->
                <div style="background-color: #f2f3f9;padding: 8px 6px;border-radius: 8px;">
                    <a-input :disabled="reqExchangeAppStatus" style="font-size: 16px;" :bordered="false"
                        v-model:value="exchangeCode" placeholder="请输入兑换码" />
                </div>
            </div>

            <div style="width: 100%;margin:100px 0px 0px 0px;">
                <a-button type="primary" size="large" style="height: 49px;font-weight: 700;font-size: 16px;"
                    :loading="reqExchangeAppStatus" block="true" @click="exchangeApp()">兑
                    换</a-button>
            </div>

            <div style="width: 100%;margin:30px 0px 0px 0px;">
            </div>
        </a-modal>

        <div class="table-create-app" style="margin-top:5%">
            <a-table :columns="columns" :data-source="getAppLogData"
                :scroll="getAppLogData.length > 0 ? {x:500, y: 350 } : ''">
                <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'getAppType'">
                        <div v-if="record.getAppType == 0" style="display: flex;align-items: center;">
                            安卓
                        </div>
                        <div v-else-if="record.getAppType == 1" style="display: flex;align-items: center;">
                            Win
                        </div>
                        <div v-else-if="record.getAppType == 2" style="display: flex;align-items: center;">
                            Mac
                        </div>
                        <div v-else-if="record.getAppType == 3" style="display: flex;align-items: center;">
                            Ios
                        </div>
                    </template>
                    <template v-if="column.key === 'getAppMethod'">
                        <div v-if="record.getAppMethod == 0" style="display: flex;align-items: center;">
                            兑换码
                        </div>
                        <div v-else-if="record.getAppMethod == 1" style="display: flex;align-items: center;">
                            购买App
                        </div>
                    </template>
                </template>
            </a-table>
        </div>

        <canvas ref="canvas"></canvas>
    </div>
</template>

<script>
    import confetti from 'canvas-confetti';
    import { message } from 'ant-design-vue';
    export default {
        props: {
            msg: String
        },

        data() {
            return {
                showExchangeAppModal: false,
                exchangeCode: null,
                reqExchangeAppStatus: false,
                getAppLogData: [],
                columns: [
                    { title: 'App类型', width: '15%', dataIndex: 'getAppType', key: 'getAppType' },
                    { title: '获取方式', dataIndex: 'getAppMethod', key: 'getAppMethod' },
                    { title: '兑换码', dataIndex: 'exchangeCode', key: 'exchangeCode' },
                    { title: '获取价格', dataIndex: 'getAppPrice', key: 'getAppPrice' },
                    { title: '获取时间', width: '18%', dataIndex: 'createDate', key: 'createDate' },
                ],
            };
        },
        created() {
            this.getAppLog()
        },
        methods: {
            exchangeApp() {



                if (this.reqExchangeAppStatus) {
                    return;
                }

                this.reqExchangeAppStatus = true

                const params = new URLSearchParams();
                params.append('code', this.exchangeCode);
                this.$http.post('/exchange/app', params).then(val => {
                    this.reqExchangeAppStatus = false
                    if (val) {
                        this.showExchangeAppModal = false
                        this.explodeConfetti()
                        this.getAppLog()
                        this.exchangeCode = null
                        message.success("成功兑换App")
                    }
                }).catch(e => {
                    console.log(e)
                    this.reqExchangeAppStatus = false
                })
            },

            getAppLog() {
                this.$http.get('/exchange/log').then(val => {
                    if (val) {
                        for (var index = 0; index < val.length; index++) {
                            if (val[index].createDate) {
                                val[index].createDate = this.timestampToTime(val[index].createDate)
                            }

                            val[index].getAppPrice += "USDT"
                            val[index].key = index
                        }

                        this.getAppLogData = val

                    }
                }).catch(e => {
                    console.log(e)
                })
            },

            timestampToTime(timestamp) {
                var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
                var Y = date.getFullYear() + '-';
                var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
                var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
                var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                return Y + M + D + ' ' + h + ':' + m + ':' + s;
            },

            explodeConfetti() {
                confetti({
                    particleCount: 200,
                    spread: 160,
                    startVelocity: 40,
                    ticks: 4000,
                    shapes: ['square', 'circle'],
                    colors: ['#0f0', '#00f'],
                    origin: { y: 0.8 },
                });
            },
        }
    }
</script>

<style scoped>
    .my-context {
        text-align: left;
        width: 100%;
        height: 100%;
    }

    .modifl-input {
        margin-top: 25px;
    }
</style>