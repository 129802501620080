import { createApp } from 'vue'
import App from './App.vue'
import 'ant-design-vue/dist/reset.css';
import AntD from 'ant-design-vue';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "@/assets/fonts/font.css";
import router from '@/router/index.js';
import http from '@/http/http';

const app = createApp(App)
app.use(AntD);
app.use(router);
app.config.globalProperties.$http = http;
app.mount('#app');
export const globalVue = app;

