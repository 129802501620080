// router.js
import { createRouter, createWebHashHistory } from 'vue-router';
import Index from '../views/Index-view.vue';
import User from '../views/User-view.vue';
import UserIndex from '../components/user-index.vue';
import CreateApp from '../components/create-app.vue';
import ExchangeApp from '../components/exchange-app.vue';

const routes = [
  { 
    path: '/',
    component: Index,
  
  },
  {
    path: '/user',
    component: User,
    meta: { requiresAuth: true }, // 添加 requiresAuth 元信息表示需要鉴权
    children:[
      { 
        path: '',
        name:'userIndex',
        component: UserIndex
      },
      {
        path: 'create',
        name:'createApp',
        component: CreateApp
      },
      {
        name:'exchangeApp',
        path:'exchangeApp',
        component:ExchangeApp,
      }
  ]
 },
  
  
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
  const isAuthenticated = checkAuthentication();
  if(isAuthenticated && to.path=="/"){
    next("/user"); 
  }else if (to.meta.requiresAuth && !isAuthenticated) {
      next('/');
  } else {
      next();
  }
});

function checkAuthentication() {
  return localStorage.getItem('token');
}

export default router;
