<template>
  <div class="context">

    <a-modal class="modal-info" v-model:open="showInfo" :maskClosable="true" :footer="null">
      <template #title>
        <div ref="modalTitleRef"
          style="width: 100%;font-size: 24px;font-weight: 600;color: #494949;margin:20px 0px 55px 0px;">我的
          个人数据
        </div>

      </template>

      <div>
        <h4 style="margin-bottom: 5px;opacity: 0.8;">我的邮箱</h4>
        <div style="background-color: #f2f3f9;padding: 8px 6px;border-radius: 8px;">
          <a-input :disabled="true" style="font-size: 16px;" :bordered="false" v-model:value="userInfo.email"
            placeholder="我的邮箱" />
        </div>
      </div>

      <div style="width: 100%;margin:40px 0px 0px 0px;opacity: 0.8;">
        <h4 style="margin-bottom: 5px;">注册时间</h4>
        <div style="background-color: #f2f3f9;padding: 8px 6px;border-radius: 8px;">
          <a-input :disabled="true" style="font-size: 16px;" :bordered="false" :value="formatDate(userInfo.createDate)"
            placeholder="注册时间" />
        </div>
      </div>


      <div style="width: 100%;margin:40px 0px 20px 0px;">

      </div>
    </a-modal>

    <a-modal class="modal-info" v-model:open="showResetToken" :closable="!resetTokenStatus" :maskClosable="false"
      :footer="null">
      <template #title>
        <div ref="modalTitleRef"
          style="width: 100%;font-size: 24px;font-weight: 600;color: #494949;margin:20px 0px 55px 0px;">重置 我的令牌
        </div>

      </template>

      <div>
        <h4 style="margin-bottom: 5px;opacity: 0.8;">我的邮箱</h4>
        <div style="background-color: #f2f3f9;padding: 8px 6px;border-radius: 8px;">
          <a-input :disabled="true" style="font-size: 16px;" :bordered="false" v-model:value="userInfo.email"
            placeholder="我的邮箱" />
        </div>
      </div>

      <div style="width: 100%;margin:100px 0px 0px 0px;">
        <a-button type="primary" size="large" style="height: 49px;font-weight: 700;font-size: 16px;"
          :loading="resetTokenStatus" block="true" @click="resetToken()">发
          送</a-button>
      </div>

      <div style="width: 100%;margin:30px 0px 0px 0px;">
      </div>
    </a-modal>

    <div class="top-label">
      <div class="label-left">AppAutoPack</div>
      <div class="label-right">
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link" @click.prevent>
            <i class="bi bi-person-circle" style="font-size: 1.5em;color: rgb(161, 208, 255)"></i>
            <DownOutlined />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item class="phone-router" style="color: rgb(51, 51, 51);font-size: 1.1em;font-weight: 400;"
                :class="{'atcive-my-app-link':isActive('userIndex')}">
                <router-link to="/user" style="display: flex;align-items: center;"><i class="bi bi-app-indicator"
                    style="font-size: 1.6em;font-weight: 100;width: 30%;"></i>我的App</router-link>

              </a-menu-item>
              <a-menu-item class="phone-router" :class="{'atcive-my-app-link':isActive('createApp')}"
                style="color: rgb(51, 51, 51);font-size: 1.1em;font-weight: 400;">
                <router-link to="/user/create"><i class="bi bi-command"
                    style="font-size: 1.5em;font-weight: 100;width: 10%;margin-right: 15%;"></i>构建任务</router-link>
              </a-menu-item>
              <a-menu-item class="phone-router" :class="{'atcive-my-app-link':isActive('exchangeApp')}"
                style="color: rgb(51, 51, 51);font-size: 1.1em;font-weight: 400;">
                <router-link to="/user/exchangeApp"><i class="bi bi-box-seam"
                    style="font-size: 1.5em;font-weight: 100;width: 10%;margin-right: 15%;"></i>兑换App</router-link>
              </a-menu-item>
              <a-menu-item class="phone-router" :class="{'atcive-my-app-link':isActive('appTransfer')}"
                style="color: rgb(51, 51, 51);font-size: 1.1em;font-weight: 400;">
                <router-link to="/user/create"><i class="bi bi-columns-gap"
                    style="font-size: 1.5em;font-weight: 100;width: 10%;margin-right: 15%;"></i>App转让</router-link>
              </a-menu-item>
              <a-menu-item key="0" @click="showInfo = true"
                style="color: rgb(51, 51, 51);font-size: 1.1em;font-weight: 300;">
                {{userInfo.email ? userInfo.email : "数据加载..."}}
              </a-menu-item>
              <a-menu-item key="1" @click="showResetToken = true"
                style="color: rgb(51, 51, 51);font-size: 1.1em;font-weight: 300;">
                重置令牌
              </a-menu-item>
              <a-menu-item key="3" @click="userOut"
                style="color: rgb(51, 51, 51);font-size: 1.1em;font-weight: 300;">登出</a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>

    <div class="crad-navigation">
      <div class="nav">
        <div class="logo-wrap">
          <img src="../assets/logo.png" width="72px" height="72px" />
          <!-- <h1>AppAutoPack</h1> -->
          <h4>让应用构建更简单</h4>
        </div>
        <ul>
          <li :class="{ active: isActive('userIndex')}">
            <div style="width: 80px;height: 80px;border-radius: 22px;">
              <router-link to="/user" :class="{'my-app-link':!isActive('userIndex')}"><i class="bi bi-app-indicator"
                  style="font-size: 30px;font-weight: 100;"></i>我的App</router-link>
            </div>
          </li>
          <li :class="{ active: isActive('createApp')}">
            <div style="width: 80px;height: 80px;border-radius: 22px;">
              <router-link to="/user/create" :class="{'my-app-link':!isActive('createApp')}"><i class="bi bi-command"
                  style="font-size: 30px;font-weight: 100;"></i>构建任务</router-link>
            </div>
          </li>
          <li :class="{ active: isActive('exchangeApp')}">
            <div style="width: 80px;height: 80px;border-radius: 22px;">
              <router-link to="/user/exchangeApp" :class="{'my-app-link':!isActive('exchangeApp')}"><i
                  class="bi bi-box-seam" style="font-size: 30px;font-weight: 100;"></i>兑换App</router-link>
            </div>
          </li>
          <li :class="{ active: isActive('buyApp')}">
            <div style="width: 80px;height: 80px;border-radius: 22px;opacity: 0.5;cursor: default !important;">
              <a :class="{'my-app-link':isActive('buyApp')}"><i class="bi bi-cart2"
                  style="font-size: 30px;font-weight: 100;"></i>购买App</a>
            </div>
          </li>
          <li :class="{ active: isActive('appTransfer')}">
            <div style="width: 80px;height: 80px;border-radius: 22px;opacity: 0.5;cursor: default !important;">
              <a :class="{'my-app-link':isActive('buyApp')}"><i class="bi bi-cart2"
                  style="font-size: 30px;font-weight: 100;"></i>App转让</a>
            </div>
          </li>
        </ul>
      </div>
      <!-- <a-menu :theme="theme" mode="vertical" :selectedKeys="[1]">
        <a-menu-item key="1">
          <router-link to="/user">我的App</router-link>
        </a-menu-item>
        <a-menu-item key="2">
          <router-link to="/user/create">构建任务</router-link>
        </a-menu-item>
        <a-menu-item key="3">
          购买App
        </a-menu-item>
        <a-menu-item key="4">
          App转让
        </a-menu-item>
        <a-menu-item key="5">
          兑换App
        </a-menu-item>
        其他菜单项... -->
      <!-- </a-menu> -->
    </div>

    <div class="link-context">
      <router-view></router-view>
    </div>

    <div class="version">
      AppAutoPack v0.0.1
    </div>

  </div>
</template>

<script>
  import { message } from 'ant-design-vue';

  export default {
    props: {
      msg: String
    },
    data() {
      return {
        theme: 'light', // 主题，可以是 'light' 或 'dark'
        mode: 'horizontal', // 模式，可以是 'horizontal' 或 'vertical'
        showInfo: false,
        userInfo: {},
        showResetToken: false,
        resetTokenStatus: false
      };
    },

    created() {
      this.getUserInfo()
    },

    methods: {
      resetToken() {
        if (this.resetTokenStatus) {
          return
        }

        this.resetTokenStatus = true
        this.$http.get('/user/reset').then(data => {
          this.resetTokenStatus = false
          if (data) {
            this.resetToken = false
            this.showResetToken = false
            localStorage.clear()
            message.success("请前往邮箱查看最新的令牌")
            setTimeout(() => {
              location.reload()
            }, 3000)
          }
        }).catch(error => {
          this.resetTokenStatus = false
          console.log(error)

        })
      },

      formatDate(d) {
        const timestamp = d // Example timestamp
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      },

      getUserInfo() {
        this.$http.get('/user/info').then(data => {
          if (data) {
            this.userInfo = data
          }
        }).catch(error => {
          console.log(error)

        })
      },

      isActive(routeName) {

        return this.$route.name === routeName;
      },

      userOut() {
        localStorage.clear();
        this.$router.push('/');
      }
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .context {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #f2f3f9;
  }



  .label-left {
    width: 15%;
    color: #f5f5f7;
    font-size: 15px;
    font-weight: 600;
    font-family: 'PressStart2PRegular';
    display: flex;
    align-items: center;
  }

  .top-label {
    width: 100%;
    height: 8%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3%;
    background-color: #1055ff;
    box-shadow: 0 2px 4px rgba(49, 70, 102, .1);
    margin-bottom: 2%;
  }

  .bi-person-circle:hover {
    color: #ffffff !important;
    cursor: pointer;
  }

  .crad-navigation {
    float: left;
    /* background-color: #ffffff; */
    height: 100%;
    width: 15%;
    border-radius: 18px;
    /* box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; */
  }

  .version {
    position: absolute;
    bottom: 10px;
    left: 15px;
    opacity: 0.2;
    font-size: 10px;
    color: #495057;
  }

  .link-context {
    float: left;
    width: 82%;
    height: 82%;
    margin: 0px auto;
    padding: 3% 3% 2% 3%;
    background-color: #fff;
    border-radius: 38px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  }

  .active {
    color: #f5f5f7;
  }

  .nav {
    background-color: #1055ff;
    width: 62%;
    height: 82%;
    margin: 0% auto;
    border-radius: 30px;
  }

  .nav>ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .nav>ul>li {
    margin-bottom: 20px;
  }

  .nav>ul>li>div {
    background-color: #ffffff0f;
    transition: background-color .2s linear;
  }

  .nav>ul>li>div>a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-size: 13px;
    color: #ffffff;
    font: 13px/1.5 "Microsoft Yahei", "Yuanti SC", "Source Han Sans CN", -apple-system !important;
    letter-spacing: 0.5px;
    transition: color .2s linear;
    border-radius: 22px;
  }

  .active>div {
    background-color: #fff !important;
  }

  .my-app-link:hover {
    color: #FFD65D !important;
    background: rgba(255, 255, 255, 0.1);
  }

  .active>div>a {
    color: #364CC6 !important;
    cursor: default;
  }

  .logo-wrap {
    padding: 20px 0px;
    width: 100%;
  }

  .logo-wrap>h4 {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 300;
    color: #fff;
  }
</style>