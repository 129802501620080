<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: 'App',

  }
</script>

<style>
  #app {
    font-family: "SF Pro SC", "SF Pro Display", "SF Pro Icons", "PingFang SC", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none;
    text-align: center;
    letter-spacing: 1px;
    text-overflow: ellipsis;
    overflow: hidden;
    /* white-space: nowrap; */
  }

  .ant-modal-content {
    border-radius: 18px !important;
    padding: 15px 45px !important;
  }

  .ant-modal-close-x {
    font-size: 20px !important;
  }

  a {
    text-decoration: none;
  }

  img {
    user-drag: none;
    user-select: none;
    /* 防止文字被选中，可选 */
    -moz-user-select: none;
    /* Firefox */
    -webkit-user-drag: none;
    /* Safari 和 Chrome */
  }

  body {
    font-size: 16px;
    padding-bottom: constant(safe-area-inset-bottom) !important;
    padding-bottom: env(safe-area-inset-bottom);
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);

  }

  .atcive-my-app-link {
    background-color: #e6f4ff !important;
    color: #1677ff !important;
  }

  .phone-router {
    display: none !important;
  }

  @media screen and (max-width:600px) {
    body {
      font-size: 15px !important;
    }

    .title {
      /* padding: 5px 10px 15px 10px !important;
      margin-bottom: 20px !important; */
    }



    .open_login {
      font-size: 0.8em !important;
    }

    .my-app-context {
      width: 100% !important;
      padding: 10px;
      margin-top: 15px;
    }

    .my-app-context>div {
      margin-right: 30px !important;
    }

    .link-context {
      border-radius: 20px !important;
    }

    .ant-modal-content {
      padding: 5px 30px !important;
    }

    .version {
      position: absolute !important;
      bottom: 10px !important;
      left: 35% !important;
    }

    .phone-router {
      display: block !important;
      font-size: 1.1em !important;
    }

    .bottom-introduce {
      width: 95% !important;
    }

    .bottom-introduce>h1 {
      margin-bottom: 7% !important;
      font-size: 3em !important;
    }

    .bottom-introduce>h2 {
      width: 90% !important;
      margin-bottom: 7% !important;
      text-align: center !important;
    }

    .bottom-introduce>.open_login {
      margin-bottom: 8% !important;
      width: 40% !important;
      height: 6% !important;
    }

    .bi-person-circle {
      font-size: 1.9em !important;
    }

    .open_login>span {
      font-size: 1.3em !important;
    }
  }

  @media screen and (max-width: 950px) {
    .phone-router {
      display: block !important;
    }


    body {
      font-size: 15px;
    }

    .crad-navigation {
      display: none !important;
    }

    .link-context {
      float: none !important;
      margin: 5% auto !important;
      width: 95% !important;
      height: 85% !important;
    }

    .label-left {
      display: none !important;
    }
  }
</style>