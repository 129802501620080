<template>
    <div class="my-context">
        <h2 class="title">
            App
            <div
                style="width: 24px;height: 24px;color: rgb(0, 112, 201);margin: 0% 0% 1% 1% ;cursor: pointer;border-radius: 18px;">

                <a-dropdown :trigger="['click']">
                    <a class="ant-dropdown-link" @click.prevent>
                        <svg viewBox="0 0 290 290" class="SvgIcon__Icon-sc-194a9zm-0 iFBZHH"
                            style="color: rgb(0, 112, 201);">
                            <path fill-rule="evenodd" style="color: #f2f3f9;"
                                d="M145 285c77.017 0 140-63 140-140S222.017 5 145 5C67.983 5 5 68 5 145s62.9829 140 140 140zm-.171-73.5854c-9.0711 0-13.8632-6.4878-13.8632-15.878v-36.7073H91.2592c-9.5844 0-16.088-4.7805-16.088-13.6586 0-9.2195 6.1613-14.1707 16.088-14.1707h39.7066V90.878c0-9.3901 4.7921-15.878 13.8631-15.878 8.8997 0 14.2054 6.1463 14.2054 15.878V131h39.8776c9.9267 0 15.917 4.9512 15.917 14.1707 0 8.878-6.3325 13.6586-15.917 13.6586h-39.8776v36.7073c0 9.7317-5.3057 15.878-14.2054 15.878z">
                            </path>
                        </svg>
                        <DownOutlined />
                    </a>
                    <template #overlay>
                        <a-menu>
                            <a-menu-item key="0"
                                style="color: rgb(0, 0, 0);font-size: 1em;font-weight: 200;margin: 8px;">
                                <router-link to="/user/exchangeApp"
                                    style="display: flex;align-items: center;">选购App</router-link>
                            </a-menu-item>
                            <a-menu-item key="1"
                                style="color: rgb(0, 0, 0);font-size: 1em;font-weight: 200;margin: 8px;">
                                <router-link to="/user/exchangeApp"
                                    style="display: flex;align-items: center;">兑换App</router-link>
                            </a-menu-item>
                        </a-menu>
                    </template>
                </a-dropdown>
            </div>
        </h2>

        <div class="my-app">
            <a-spin size="large" v-show="reqDataStatus" />
            <div class="my-app-context" v-show="!reqDataStatus" v-for="(item,index) in appList" :key="index"
                @click="openApp(item)">
                <div style="margin-right: 5%;">
                    <!-- <div  :style="' background-image: url('{{item.appImgUrl}}');'"> -->

                    <img :src="item.appImgUrl == null ? appImgUrl : item.appImgUrl " class="my-app-item"
                        @error="errorImg" />
                    <!-- </div> -->
                </div>
                <div>
                    <h2 class="app-item-name">{{item.appName}}</h2>
                    <a class="app-item-tip">修改 App 配置数据&nbsp;<svg style="width: 8px;height: 8px;" viewBox="0 0 290 290"
                            color="ctrl" ml="spacer5" class="SvgIcon__Icon-sc-194a9zm-0 bmjQOK">
                            <path fill-rule="evenodd"
                                d="M81.5432 7.8866C79.7668 6.1103 77.5464 5 75.104 5 69.9969 5 66 8.997 66 14.104c0 2.2204.6661 4.663 2.4425 6.2171l134.1158 124.79L68.4425 269.4567C66.6661 271.233 66 273.6757 66 276.1182c0 5.107 4.2189 8.8818 9.1039 8.8818 2.4425 0 4.4409-.8882 6.4393-2.6646l139.667-129.6748c2.2204-2.2205 3.7747-4.885 3.7747-7.7716 0-2.8866-1.3323-5.329-3.5527-7.5496L81.5432 7.8866z">
                            </path>
                        </svg></a>
                </div>
            </div>
        </div>

        <a-modal class="modal-app" v-model:open="showAppContext" :closable="!appDataSaveStatus" :maskClosable="false"
            :footer="null">
            <template #title>
                <div ref="modalTitleRef" v-if="activeAppData.appType == 0"
                    style="width: 100%;font-size: 24px;font-weight: 600;color: #494949;margin:20px 0px 35px 0px;">
                    App&nbsp;配置(安卓)
                </div>
                <div ref="modalTitleRef" v-if="activeAppData.appType == 1"
                    style="width: 100%;font-size: 24px;font-weight: 600;color: #494949;margin:20px 0px 35px 0px;">
                    App&nbsp;配置(Win)
                </div>
                <div ref="modalTitleRef" v-if="activeAppData.appType == 2"
                    style="width: 100%;font-size: 24px;font-weight: 600;color: #494949;margin:20px 0px 35px 0px;">
                    App&nbsp;配置(Mac)
                </div>
                <div ref="modalTitleRef" v-if="activeAppData.appType == 3"
                    style="width: 100%;font-size: 24px;font-weight: 600;color: #494949;margin:20px 0px 35px 0px;">
                    App&nbsp;配置(Ios)
                </div>
            </template>

            <div class="modifl-input">
                <h4>应用名称</h4>
                <div style="background-color: #f2f3f9;padding: 8px 6px;border-radius: 8px;">
                    <a-input :disabled="modifyAppContextStatus" style="font-size: 16px;" :bordered="false"
                        v-model:value="activeAppData.appName" placeholder="请输入APP应用名称" allowClear="true" />
                </div>
            </div>

            <div class="modifl-input">
                <h4>应用图标</h4>
                <div style="background-color: #f2f3f9;padding: 8px 6px;border-radius: 8px;">
                    <a-input :disabled="modifyAppContextStatus" style="font-size: 16px;" :bordered="false"
                        v-model:value="activeAppData.appImgUrl" placeholder="请输入应用图标地址" allowClear="true" />
                </div>
            </div>

            <div class="modifl-input">
                <h4>默认注册邀请码(可为空)</h4>
                <div style="background-color: #f2f3f9;padding: 8px 6px;border-radius: 8px;">
                    <a-input :disabled="modifyAppContextStatus" style="font-size: 16px;" :bordered="false"
                        v-model:value="activeAppData.inviteUrl" placeholder="请输入注册邀请码" allowClear="true" />
                </div>
            </div>

            <div class="modifl-input" v-if="activeAppData.appType == 0">
                <h4>CPU架构</h4>
                <a-checkbox-group v-model:value="activeAppData.cpuArchitecture" style="width: 100%">
                    <a-row>
                        <a-col :span="6">
                            <a-checkbox value="arm64-v8a">arm-v8a</a-checkbox>
                        </a-col>
                        <a-col :span="6">
                            <a-checkbox value="armeabi-v7a">arm-v7a</a-checkbox>
                        </a-col>
                        <a-col :span="6">
                            <a-checkbox value="x86_64">x86_64</a-checkbox>
                        </a-col>
                        <a-col :span="6">
                            <a-checkbox value="x86">x86</a-checkbox>
                        </a-col>

                    </a-row>
                </a-checkbox-group>
            </div>

            <div class="modifl-input">
                <h4>配置文件Api</h4>
                <div style="background-color: #f2f3f9;padding: 8px 6px;border-radius: 8px;">
                    <a-input :disabled="copyActiveAppData.setupApi != null||modifyAppContextStatus"
                        style="font-size: 16px;" :bordered="false" v-model:value="activeAppData.setupApi"
                        placeholder="请输入配置文件Api" allowClear="true" />
                </div>
            </div>

            <div class="modifl-input">
                <h4>配置文件秘钥</h4>
                <div style="background-color: #f2f3f9;padding: 8px 6px;border-radius: 8px;">
                    <a-input :disabled="modifyAppContextStatus" style="font-size: 16px;" :bordered="false"
                        v-model:value="activeAppData.setupApiSecretKey" placeholder="请输入配置文件秘钥" allowClear="true" />
                </div>
            </div>

            <div style="width: 100%;margin:60px 0px 20px 0px;transition: opacity 0.5s;"
                :style="{opacity:(!appDataSaveStatus && saveAppDataStatus ? 1 : 0.4)}">
                <a-button type="primary" size="large" style="height: 49px;font-weight: 700;font-size: 16px;"
                    :loading="appDataSaveStatus" @click="appDataSave(activeAppData)" block="true">保 存</a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import { message } from 'ant-design-vue';
    import appImage from "../assets/app.png";
    export default {
        props: {
            msg: String
        },
        data() {
            return {
                showAppContext: false,
                modifyAppContextStatus: false,
                activeAppData: {},
                copyActiveAppData: {},
                appDataSaveStatus: false,
                saveAppDataStatus: false,
                reqDataStatus: false,
                appList: [
                ],
                appImgUrl: appImage
            };
        },
        watch: {
            activeAppData: {
                handler(nowData) {
                    if (nowData.appName != null && nowData.appName != "") {
                        if (nowData.appImgUrl != null && nowData.appImgUrl != "") {
                            if (nowData.setupApiSecretKey != null && nowData.setupApiSecretKey != "") {
                                if (nowData.setupApi != null && nowData.setupApi != "") {
                                    if (nowData.cpuArchitecture && nowData.cpuArchitecture.length > 0) {
                                        this.saveAppDataStatus = true;
                                        return;
                                    }
                                }
                            }
                        }
                    }

                    this.saveAppDataStatus = false;
                },
                deep: true
            },
        },
        created() {
            this.getAllAPP()
        },
        methods: {
            appDataSave(data) {


                if (!this.appDataSaveStatus && !this.saveAppDataStatus) {
                    return
                }

                if (this.activeAppData.appType == 0 && !data.cpuArchitecture && data.cpuArchitecture.length < 1) {
                    return
                }

                data = JSON.parse(JSON.stringify(data))

                this.appDataSaveStatus = true
                var cpuArchitecture = ""
                for (var index = 0; index < data.cpuArchitecture.length; index++) {
                    if (index < data.cpuArchitecture.length - 1) {
                        cpuArchitecture += data.cpuArchitecture[index] + ","
                    } else {
                        cpuArchitecture += data.cpuArchitecture[index]
                    }

                }
                data.cpuArchitecture = cpuArchitecture

                this.$http.post('/app/modify', data).then(data => {
                    this.appDataSaveStatus = false
                    if (data) {
                        this.getAllAPP()
                        this.showAppContext = false
                        message.success("保存App成功")

                    }

                }).catch(error => {
                    this.appDataSaveStatus = false
                    console.log(error)
                })

            },

            openApp(item) {
                this.copyActiveAppData = JSON.parse(JSON.stringify(item));
                this.activeAppData = JSON.parse(JSON.stringify(item));

                this.showAppContext = true;
            },

            getAllAPP() {

                this.reqDataStatus = true
                this.$http.get('/app/get').then(data => {
                    this.reqDataStatus = false
                    if (data) {
                        for (var index = 0; index < data.length; index++) {
                            if (data[index].cpuArchitecture) {
                                data[index].cpuArchitecture = data[index].cpuArchitecture.split(",")
                            }
                        }
                        this.appList = data
                    }

                }).catch(error => {
                    this.reqDataStatus = false
                    console.log(error)
                })
            },

            errorImg(event) {

                event.target.src = this.appImgUrl
                event.srcElement.onerror = null; //防止闪图
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .my-context {
        text-align: left;
        width: 100%;
        height: 100%;
    }

    .title {
        margin-bottom: 5%;
        color: rgb(17, 17, 17) !important;
        font-weight: 600 !important;
        font-size: 2em;
        border-bottom: rgb(214, 214, 214) 1px solid;
        padding-bottom: 15px;
        display: flex;
        align-items: center;
        font-family: "SF Pro Display", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    }

    .my-app {
        width: 100%;
        height: 80%;
        display: flex;
        overflow: auto;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
    }

    .my-app-context {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 8px;
        margin-bottom: 5%;
        cursor: pointer;
        width: 33%;
        /* transition: transform 0.3s ease */
    }

    .my-app-context:hover {
        /* transform: scale(1.03); */
        opacity: 0.8;
    }

    .my-app-context>image {
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .my-app-context>h2 {
        margin-top: 10px;
        width: 100%;
        font-size: 14px !important;
        font-weight: 300 !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
    }

    .modifl-input {
        margin-top: 25px;
    }

    .modifl-input>h4 {
        margin-bottom: 5px;
    }

    svg path {
        fill: rgb(0, 112, 201);
    }

    svg:hover {
        opacity: 0.9;
    }

    .my-app-item {
        width: 100px;
        height: 100px;
        position: relative;

        background-size: 100%;
        background-repeat: no-repeat;
    }

    .my-app-context {
        width: 40%;
        height: 100px;
    }

    .my-app-item::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEuNDEiIGNsaXAtcnVsZT0iZXZlbm9kZCIgdmlld0JveD0iMCAwIDQ2MCA0NjAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLW9wYWNpdHk9Ii4xIiB2ZWN0b3ItZWZmZWN0PSJub24tc2NhbGluZy1zdHJva2UiIGQ9Ik0zMTUuOTUgNDU5LjU2aC0xNzEuOWMtNS40OCAwLTEwLjk2IDAtMTYuNDQtLjAzLTQuNjItLjAzLTkuMjQtLjA4LTEzLjg2LS4yMWEyMDEuMDUgMjAxLjA1IDAgMCAxLTMwLjE2LTIuNjVjLTkuOTctMS43LTE5LjYzLTQuOS0yOC42Ni05LjQ1YTk2LjQ1IDk2LjQ1IDAgMCAxLTQyLjE2LTQyLjE0IDEwMS43NiAxMDEuNzYgMCAwIDEtOS40NC0yOC42OSAyMDEuMzMgMjAxLjMzIDAgMCAxLTIuNjYtMzAuMTVjLS4xMi00LjYyLS4xOC05LjIzLS4yLTEzLjg1LS4wNC01LjQ5LS4wNC0xMC45Ny0uMDQtMTYuNDZWMTQ0LjA1YzAtNS40OSAwLTEwLjk3LjA0LTE2LjQ2LjAyLTQuNjIuMDgtOS4yNC4yLTEzLjg2LjEzLTEwLjEgMS4wMi0yMC4xNyAyLjY2LTMwLjE0IDEuNy05Ljk4IDQuODktMTkuNjQgOS40NS0yOC42OGE5Ni40MyA5Ni40MyAwIDAgMSA0Mi4xNS00Mi4xNUExMDIuMDYgMTAyLjA2IDAgMCAxIDgzLjU5IDMuMyAyMDEuMDYgMjAxLjA2IDAgMCAxIDExMy43NS42NmM0LjYyLS4xMiA5LjI0LS4xOCAxMy44Ni0uMiA1LjQ4LS4wMyAxMC45Ny0uMDMgMTYuNDUtLjAzaDE3MS44OWM1LjQ5IDAgMTAuOTcgMCAxNi40NS4wMyA0LjYyLjAyIDkuMjQuMDggMTMuODYuMmEyMDEuMiAyMDEuMiAwIDAgMSAzMC4xNiAyLjY1YzkuOTcgMS43IDE5LjYzIDQuOSAyOC42NiA5LjQ1YTk2LjQxIDk2LjQxIDAgMCAxIDQyLjE2IDQyLjE1YzQuNTYgOS4wNCA3Ljc0IDE4LjcgOS40NSAyOC42OGEyMDEuMiAyMDEuMiAwIDAgMSAyLjY1IDMwLjE1Yy4xMiA0LjYyLjE4IDkuMjQuMiAxMy44NWwuMDIgNi45NXYxODguMjRsLS4wMiA5LjZjLS4wMiA0LjYyLS4wOSA5LjI0LS4yIDEzLjg2YTIwMS4zNCAyMDEuMzQgMCAwIDEtMi42NiAzMC4xNSAxMDEuNDIgMTAxLjQyIDAgMCAxLTkuNDUgMjguNjggOTYuNDQgOTYuNDQgMCAwIDEtNDIuMTUgNDIuMTUgMTAxLjY5IDEwMS42OSAwIDAgMS0yOC42NiA5LjQ1IDIwMS4wNSAyMDEuMDUgMCAwIDEtMzAuMTYgMi42NWMtNC42Mi4xMi05LjI0LjE4LTEzLjg2LjJsLTE2LjQ1LjA0eiIvPjwvc3ZnPg==") 50%/cover no-repeat;
    }

    .app-item-name {
        font-size: 1.2em;
        font-weight: bold;
        color: #111111;
        letter-spacing: 0 !important;
        font-family: "SF Pro Display", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif !important;
    }

    .app-item-name:hover {
        color: rgb(0, 112, 201);
        ;
    }

    .app-item-tip {
        font-size: 0.75em;
        color: #0070c9;
        font-weight: 400;
        display: flex;
        align-items: center;
        font-family: "SF Pro Text", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif !important;
        letter-spacing: 0 !important;
    }
</style>