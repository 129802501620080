<template>
    <div class="my-context">
        <div style="display: flex;justify-content: space-between;">
            <h2 class="title">构建你的App</h2>
            <div class="create-app" @click="showCreateApp = true,activeuseBuildAppOptions = null"
                style="color:#2869ff;font-size: 16px;cursor: pointer;display: flex;align-items: center;font-weight: 500;">
                <i class="bi bi-cloud-plus-fill" style="font-size: 20px;"></i>&nbsp;云构建
            </div>
        </div>

        <a-modal class="modal-app" v-model:open="showCreateApp" :closable="!reqBuildStatus" :maskClosable="false"
            :footer="null">
            <template #title>
                <div ref="modalTitleRef"
                    style="width: 100%;font-size: 24px;font-weight: 600;color: #494949;margin:20px 0px 45px 0px;">
                    App&nbsp;构建
                </div>
            </template>

            <div>
                <h4 style="margin-bottom: 5px;">选择应用</h4>
                <div style="background-color: #f2f3f9;padding: 8px 6px;border-radius: 8px;">
                    <a-select v-model:value="activeuseBuildAppOptions" :options="useBuildAppOptions" :bordered="false"
                        placeholder="请选择要构建的应用" style="width: 100%" :disabled="reqBuildStatus" :showSearch="true" />
                </div>
            </div>

            <div style="width: 100%;margin:80px 0px 20px 0px;transition: opacity 0.5s;"
                :style="{opacity:(activeuseBuildAppOptions != null ? 1 : 0.4) }" :class="{'active-but':reqBuildStatus}">
                <a-button type="primary" size="large" style="height: 49px;font-weight: 700;font-size: 16px;"
                    :loading="reqBuildStatus" @click="createBuildApp()" block="true">构
                    建</a-button>
            </div>
        </a-modal>

        <div class="table-create-app">
            <a-table :columns="columns" :data-source="buildData" class="custom-table"
                :scroll="buildData.length > 0 ? {x:500, y: 400 } : ''" :pagination="{ pageSize: 10 }">
                <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'appType'">
                        <div v-if="record.appType == 0">
                            安卓
                        </div>
                        <div v-if="record.appType == 1">
                            Win
                        </div>
                        <div v-if="record.appType == 2">
                            Mac
                        </div>
                        <div v-if="record.appType == 3">
                            Ios
                        </div>
                    </template>
                    <template v-if="column.key === 'packetStatus'">
                        <div v-if="record.packetStatus == 0" style="display: flex;align-items: center;color:#FFD65D">
                            <div
                                style="width: 8px;height: 8px;background-color: #FFD65D;border-radius: 50%;margin-right: 8px;">
                            </div>
                            队列中
                        </div>

                        <div v-else-if="record.packetStatus == 1"
                            style="display: flex;align-items: center;color: #2997ff;">
                            <div
                                style="width: 8px;height: 8px;background-color: #2997ff;border-radius: 50%;margin-right: 8px;">
                            </div>
                            正在构建
                        </div>

                        <div v-else-if="record.packetStatus == 2"
                            style="display: flex;align-items: center;color: #2ba972;">
                            <div
                                style="width: 8px;height: 8px;background-color: #2ba972;border-radius: 50%;margin-right: 8px;">
                            </div>
                            构建成功
                        </div>

                        <div v-else-if="record.packetStatus == 3"
                            style="display: flex;align-items: center;color: #f5222d;">
                            <div
                                style="width: 8px;height: 8px;background-color: #f5222d;border-radius: 50%;margin-right: 8px;">
                            </div>
                            构建失败
                        </div>
                    </template>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
    import { message } from 'ant-design-vue';
    export default {
        props: {
            msg: String
        },
        data() {
            return {
                showCreateApp: false,
                reqCreateAppStatus: false,
                activeuseBuildAppOptions: null,
                reqBuildStatus: false,
                useBuildAppOptions: [
                ],
                columns: [
                    { title: 'App名称', dataIndex: 'appName', key: 'appName' },
                    { title: '应用版本', dataIndex: 'appVersion', key: 'appVersion' },
                    { title: '应用类型', dataIndex: 'appType', key: 'appType' },
                    { title: '构建状态', dataIndex: 'packetStatus', key: 'packetStatus' },
                    { title: '构建完成时间', width: '18%', dataIndex: 'createPacketDate', key: 'createPacketDate' },
                    { title: '提交构建时间', width: '18%', dataIndex: 'submitPacketDate', key: 'submitPacketDate' },
                ],
                buildData: []
            };
        },

        created() {
            this.getUseApp()
            this.getPacketLog()
            setInterval(() => {
                this.getPacketLog()
            }, 6000)
        },
        methods: {
            getUseApp() {
                this.$http.get('/packet/getUse').then(val => {
                    if (val) {
                        this.useBuildAppOptions = []
                        for (var index = 0; index < val.length; index++) {
                            var data = {}
                            data['value'] = val[index].id
                            data['label'] = val[index].appName
                            this.useBuildAppOptions[index] = data
                        }
                    }
                })
            },

            getPacketLog() {
                this.$http.get('/packet/log').then(val => {
                    if (val) {
                        for (var index = 0; index < val.length; index++) {
                            if (val[index].createPacketDate) {
                                val[index].createPacketDate = this.timestampToTime(val[index].createPacketDate)
                            }
                            val[index].submitPacketDate = this.timestampToTime(val[index].submitPacketDate)
                            val[index].key = index
                        }

                        this.buildData = val
                    }
                }).catch(e => {
                    console.log(e)
                })
            },

            timestampToTime(timestamp) {
                var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
                var Y = date.getFullYear() + '-';
                var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
                var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
                var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                return Y + M + D + ' ' + h + ':' + m + ':' + s;
            },


            createBuildApp() {
                if (this.reqBuildStatus || !this.activeuseBuildAppOptions) {
                    return true
                }
                this.reqBuildStatus = true
                const params = new URLSearchParams();
                params.append('id', this.activeuseBuildAppOptions);
                this.$http.post('/packet/create', params).then(val => {
                    this.reqBuildStatus = false
                    if (val) {
                        this.showCreateApp = false;
                        this.getPacketLog()
                        message.success("构建已发送,请不要重复提交")
                    }
                }).catch(e => {
                    this.reqBuildStatus = false;
                    console.log(e)
                });
            },

            openApp(item) {
                this.activeAppData = JSON.parse(JSON.stringify(item));
                this.showAppContext = true;
            },

            errorImg(e) {
                console.log(e)
                e.srcElement.src = require('../assets/logo.png');
                //这一句没用，如果默认图片的路径错了还是会一直闪屏，在方法的前面加个.once只让它执行一次也没用
                e.srcElement.onerror = null; //防止闪图
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .my-context {
        text-align: left;
        width: 100%;
        height: 100%;
    }



    .modifl-input {
        margin-top: 25px;
    }

    .create-app:hover {
        opacity: 0.8;

    }

    .table-create-app {
        width: 100%;
        margin-top: 5%;
    }

    .active-but {
        opacity: 0.4 !important;
        cursor: default;
    }
</style>