<template>
  <div class="video-background">
    <video autoplay muted loop>
      <source :src="getRandomVideoSrc()" type="video/mp4" />
    </video>
    <div class="top-label">
      <div class="label-left">AppAutoPack</div>
      <div class="label-right">
        <div class="login" @click="openShowLogin()">登 录</div>
        <div class="register" @click="openShowRegister()">免费注册</div>
      </div>
    </div>
    <div class="bottom-introduce">
      <h1><span style="color: #42b883;">V2Board</span> 客户端</h1>
      <h2>让每一个机场都用得起的客户端，USDT 200起<br>贵的 刚刚好。</h2>
      <div class="open_login" @click="openShowLogin()">
        <span>进入了解</span>
        <i class="bi bi-arrow-right-short text-danger fs-8" style="font-size: 1.5rem;"></i>
      </div>
    </div>

    <a-modal class="modal-login" v-model:open="showLogin" :closable="!loginStatus" :maskClosable=" false"
      :footer="null">
      <template #title>
        <div ref="modalTitleRef"
          style="width: 100%;font-size: 24px;font-weight: 600;color: #494949;margin:20px 0px 55px 0px;">登录
          App AutoPack</div>
      </template>
      <div style="background-color: #f2f3f9;padding: 8px 6px;border-radius: 8px;">
        <a-input :disabled="loginStatus" style="font-size: 16px;" :bordered="false" v-model:value="keyValue"
          placeholder="输入令牌" allowClear="true" />
      </div>

      <!-- <div style="width: 100%;text-align: right;height: 20px;">
        <h3 style="font-size: 14px;color: #2997ff;font-weight: 400;float: right;margin-top: 15px;"
          :style="{cursor:(loginStatus ? 'default' : 'pointer'),opacity:(loginStatus ? 0.5 : 1)}">忘记了令牌?</h3>
      </div> -->

      <div style="width: 100%;margin:100px 0px 20px 0px;transition: opacity 0.5s;"
        :style="{opacity:(keyValue == null || keyValue.length < 1 ? 0.4 : 1)}">
        <a-button type="primary" size="large" style="height: 49px;font-weight: 700;font-size: 16px;"
          :loading="loginStatus" block="true" @click="tokenValid()">验
          证</a-button>
      </div>
    </a-modal>

    <a-modal class="modal-register" v-model:open="showRegister" :closable="!registerStatus" :maskClosable="false"
      :footer="null">
      <template #title>
        <div ref="modalTitleRef"
          style="width: 100%;font-size: 24px;font-weight: 600;color: #494949;margin:20px 0px 55px 0px;">注册
          App AutoPack</div>
      </template>
      <div style="background-color: #f2f3f9;padding: 8px 6px;border-radius: 8px;">
        <a-input :disabled="registerStatus" style="font-size: 16px;" :bordered="false" v-model:value="registerEmail"
          placeholder="输入邮箱" allowClear="true" />
      </div>

      <div style="width: 100%;margin:100px 0px 20px 0px;transition: opacity 0.5s;"
        :style="{opacity:(registerEmail == null || registerEmail.length < 1 ? 0.4 : 1)}">
        <a-button type="primary" size="large" style="height: 49px;font-weight: 700;font-size: 16px;"
          :loading="registerStatus" block="true" @click="registerFun()">发
          送</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
  import { message } from 'ant-design-vue';
  export default {

    props: {
      msg: String
    },
    data() {
      return {
        showLogin: false,
        showRegister: false,
        registerEmail: "",
        keyValue: "",
        loginStatus: false,
        registerStatus: false,
        videoSrc: [
          "https://openaicomproductionae4b.blob.core.windows.net/production-twill-01/121dd5e0-eaea-424d-bdf2-db02ca1f5e55/gpt-4-92586ac_1080p60.mp4",
          "https://www.apple.com.cn/105/media/us/home/2023/1d507f60-ae07-45d6-9c0e-2573c8ac8487/anim/enhanced-hero/medium_2x.mp4"
        ]
      };
    },

    methods: {
      getRandomVideoSrc() {
        const randomIndex = Math.floor(Math.random() * this.videoSrc.length);
        return this.videoSrc[randomIndex];
      },

      openShowLogin() {
        this.keyValue = null;
        this.showLogin = true;

      },

      openShowRegister() {
        this.registerEmail = null;
        this.showRegister = true;
      },

      validateEmail(email) {
        // 定义电子邮件地址的正则表达式模式
        var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (pattern.test(email)) {
          return true;
        } else {
          return false;
        }
      },

      registerFun() {
        message.destroy()
        if (!this.validateEmail(this.registerEmail)) {
          message.error("请输入正确的邮箱格式")
          return
        }

        if (this.registerEmail == null || this.registerEmail.length < 1 || this.registerStatus) {
          return
        }

        this.registerStatus = true;

        const params = new URLSearchParams();
        params.append('email', this.registerEmail);
        this.$http.post('/user/register', params).then(data => {
          this.registerStatus = false;
          if (data) {
            this.showRegister = false;
            message.success("发送成功,请前往邮箱查收")
          }
        }).catch(error => {
          console.log(error)
          this.loginStatus = false;
        })
      },

      tokenValid() {
        message.destroy()
        if (this.keyValue == null || this.keyValue.length < 1 || this.loginStatus) {
          return;
        }

        if (this.keyValue.length < 50) {
          message.error("请检查令牌格式")
          return
        }

        this.loginStatus = true;
        const params = new URLSearchParams();
        params.append('token', this.keyValue);
        this.$http.post('/user/validator', params).then(data => {
          this.loginStatus = false;
          if (data) {
            this.showLogin = false
            // message.success("验证成功,正在跳转首页")  
            localStorage.setItem("token", this.keyValue)
            this.$router.push('/user');
            // setTimeout(() => {

            // }, 1500)

          }
        }).catch(error => {
          console.log(error)
          //this.keyValue = "";
          this.loginStatus = false;
        })
      }
    }
  }


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .video-background {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .label-left {
    color: #f5f5f7;
    font-size: 18px;
    font-weight: 600;
    font-family: 'PressStart2PRegular';
  }


  .label-right>.login {
    width: 112px;
    height: 40px;
    line-height: 40px;
    border: solid 1.2px #f5f5f7;
    border-radius: 8px;
    text-align: center;
    color: #f5f5f7;
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;
    font-weight: 900;
    transition: transform 0.5s ease;
    font-size: 1.1em;
  }

  .label-right>.login:hover {
    background-color: #f5f5f7;
    color: #2997ff;
    transform: scale(1.05);
    /* 在悬停时将大小放大为原来的两倍 */
  }

  .label-right>.register {
    width: 112px;
    height: 40px;
    line-height: 40px;
    border: solid 1.2px #f5f5f7;
    border-radius: 8px;
    text-align: center;
    background-color: #f5f5f7;
    color: #2997ff;
    display: inline-block;
    cursor: pointer;
    font-weight: 900;
    transition: transform 0.3s ease;
    font-size: 1.1em;
  }

  .label-right>.register:hover {
    transform: scale(1.05);
  }

  .top-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6%;
  }

  .bottom-introduce {
    margin: 0 auto;
    text-align: center;
    color: #f5f5f7;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

  }

  .bottom-introduce>h1 {
    font-size: 2.5em;
    font-weight: 900;
    margin-bottom: 0.7em;
  }

  .bottom-introduce>h2 {
    font-size: 1.6em;
    font-weight: 400;
    margin-bottom: 1.3em;
    word-wrap: break-word !important;
  }

  .bottom-introduce>.open_login {
    width: 129px;
    background-color: #f5f5f7;
    color: #000;
    height: 44px;
    border-radius: 30px;
    margin-bottom: 1.5%;
    text-align: center;
    line-height: 44px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .open_login:hover {
    opacity: 0.9;
  }

  .open_login>span {
    font-size: 0.9em;
    font-weight: 400;
  }

  .modal-login {
    border-radius: 50px;
  }

  .but-blur {
    cursor: default;
  }
</style>